import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo";
import SimpleBlock from "../components/simpleBlock/simpleBlock";

const Terms = ()=>{
  return (
    <Layout isBg>
      <Seo
        lang={'en'}
        title="Terms of use | AI solutions"
        description={"Please carefully read the following Terms of Use and Disclaimer before using the aisolutions.kz web site."}
      />
      <SimpleBlock>
        <>
          <h1>Terms of use</h1>
          <p>
            Please carefully read the following Terms of Use and Disclaimer before using the aisolutions.kz. Web site. By using our Web site, you agree to these Terms of Use. If you do not agree to these Terms of Use, you may not use this site. aisolutions.kz reserves the right, at any time, to modify, alter or update these Terms of Use, and you agree to be bound by such modifications, alterations or updates from the time they are posted on our Web site. It is your responsibility to check the Terms of Use periodically.
          </p><h2>

            Copyright
          </h2><p>
            All content included in our Web site, such as text, graphics, logos, button icons, images, and software, is the property of aisolutions.kzor its content suppliers and protected by the U.S. and international copyright laws. Any use, including the reproduction, modification, distribution, transmission, republication, display or performance, of the content of aisolutions.kz or its respective content suppliers, is strictly prohibited.
          </p><h2>

            Trademarks
          </h2><p>

            All trademarks, logos, service marks and trade names that appear on this Web site are the trademarks of aisolutions.kz and other respective owners.
          </p><p>

            Disclaimer
          </p><p>

            The information, services, products and materials contained in and/or advertised on aisolutions.kz, including, without limitation, text, graphics, and links, are provided on an “As Is” basis with no warranty. To the maximum extent permitted by law, aisolutions.kz and its suppliers disclaim all representations and warranties, express or implied, with respect to such information, services, products and materials, including, but not limited to, warranties of merchantability for a particular purpose, title, non-infringement, freedom from computer virus and implied warranties arising from course of dealing or course of performance. In addition, aisolutions.kz and its suppliers do not represent or warrant that the information accessible via aisolutions.kz is accurate, complete or current. We are not responsible for typographical errors. Availability information is subject to change without notice.
          </p><p>

            No warranties
          </p><p>

            This Website is provided “as is,” with all faults, and aisolutions.kz makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.
            Personal Data collected for the following purposes and using the following services.
          </p><p>

            Limitation of liability
          </p><p>
            In no event shall aisolutions.kz, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and aisolutions.kz, including its officers, directors, and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.
          </p><p>
            Variation of Terms
          </p><p>
            aisolutions.kz is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing the use of this Website.
          </p><p>
            Entire Agreement
          </p><p>
            These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between aisolutions.kz and you in relation to your use of this Web site and supersede all prior agreements and understandings with respect to the same.

          </p>
        </>
      </SimpleBlock>

    </Layout>
  )
}
export default Terms