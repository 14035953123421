import * as React from "react";
import * as styles from "./simpleBlock.module.scss"

const SimpleBlock = ({children})=>{
  return (
    <div className={styles.simpleBlock}>
      <div className={styles.simpleBlockWrap}>
        {children}
      </div>
    </div>
  )
}

export default SimpleBlock